import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import {
  login,
  logout,
  cameraInfo,
  manualSendSignalAboutArrivalAuto,
  sendNewTicket,
  ticketPricing,
  allCarBrands,
  allCarModels,
  allCountries,
  timeSlots,
  holdSlot,
  queueList,
  carTicketByUuid,
  manualCarDispatch,
  replaceCarInQueue,
  releaseCarFromQueue,
  barriers,
  raiseBarrier,
  scoreboards,
  updateScoreboard,
} from '@/api/services';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    operator: isLSHasItem('operator') ? getFromLS('operator') : null,
    token: isLSHasItem('token') ? getFromLS('token') : null,
    notification: null,
  },

  getters: {
    isAuthUser: (state) => !!state.token,
    getUser: (state) => state.operator,
    getNotification: (state) => state.notification,
  },

  mutations: {
    SAVE_USER(state, payload) {
      const { token, operator } = payload;
      state.operator = operator;
      state.token = token;
      setToLS('token', token);
      setToLS('operator', operator);
    },
    LOGOUT_USER(state) {
      state.operator = null;
      state.token = null;
      localStorage.clear();
    },
    setNotification(state, notification) {
      state.notification = notification;
    },
    clearNotification(state) {
      state.notification = null;
    },
  },

  actions: {
    async authorization({ commit }, data) {
      let res;

      try {
        res = await login(data);
        const { access_token: token, operator_service_zone: operator } =
          res.data.data;

        commit('SAVE_USER', {
          token,
          operator,
        });

        router.push({ name: 'dashboard' });
      } catch (error) {
        const statusCode = error.response ? error.response.status : null;

        if (statusCode === 401) {
          return error.response.data.status;
        }

        error.handleGlobally && error.handleGlobally();
      }
    },

    async userLogout({ commit }) {
      try {
        await logout();
        commit('LOGOUT_USER');
        router.push({ name: 'auth' });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getCameraInfo() {
      try {
        const res = await cameraInfo();
        return res.data.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async manualSendSignalAboutArrivalAuto(_, data) {
      try {
        await manualSendSignalAboutArrivalAuto(data);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async sendNewTicket(_, ticket) {
      try {
        const res = await sendNewTicket(ticket);

        return res.data.data.ticket;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getTicketPricing() {
      try {
        const res = await ticketPricing();
        return res.data.data.pricing;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getAllCarBrands() {
      try {
        const res = await allCarBrands();
        return res.data.data.brands;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getAllCarModels(_, id) {
      try {
        const res = await allCarModels(id);
        return res.data.data.models;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getAllCountries() {
      try {
        const res = await allCountries();
        return res.data.data.countries;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getTimeSlots(_, data) {
      try {
        const res = await timeSlots(data);
        return res.data.data.time_slots;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async executeHoldSlot(_, data) {
      try {
        await holdSlot(data);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getQueueList(_, page) {
      try {
        const res = await queueList(page);
        return res.data.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getCarTicketByUuid(_, uuid) {
      try {
        const res = await carTicketByUuid(uuid);
        return res.data.data.ticket;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async manualCarDispatch(_, autosCount) {
      try {
        await manualCarDispatch(autosCount);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async replaceCarInQueue(_, uuid) {
      try {
        await replaceCarInQueue(uuid);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async releaseCarFromQueue(_, uuid) {
      try {
        await releaseCarFromQueue(uuid);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getBarriers() {
      try {
        const res = await barriers();
        return res.data.data.barriers;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async raiseBarrier(_, uuid) {
      try {
        await raiseBarrier(uuid);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getScoreboards() {
      try {
        const res = await scoreboards();
        return res.data.data.scoreboards;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async updateScoreboard(_, scoreboard) {
      try {
        await updateScoreboard(scoreboard);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
  },
  modules: {},
});
