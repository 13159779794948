import { Axios } from '@/api/config';

const api_version = '/api/v1.0';

export const login = (data) => {
  const url = `${api_version}/service-zone/operator/auth/sign-in`;
  return Axios.post(url, data);
};

export const logout = () => {
  const url = `${api_version}/service-zone/operator/auth/logout`;
  return Axios.post(url);
};

export const cameraInfo = () => {
  const url = `${api_version}/service-zone/broadcast`;
  return Axios.get(url);
};

export const manualSendSignalAboutArrivalAuto = (data) => {
  const url = `${api_version}/service-zone/operator/manual-send-signal-about-the-arrival-of-auto-to-main-barrier`;
  return Axios.post(url, data);
};

export const sendNewTicket = (ticket) => {
  const url = ticket.second_name
    ? `${api_version}/service-zone/operator/tickets/buy?first_name=${ticket.first_name}&last_name=${ticket.last_name}&second_name=${ticket.second_name}&email=${ticket.email}&telephone_number=${ticket.telephone_number}&type_auto=${ticket.type_auto}&country_registration_id=${ticket.country_registration_id}&auto_registration_plate=${ticket.auto_registration_plate}&car_brand_id=${ticket.car_brand_id}&car_model_id=${ticket.car_model_id}&count_of_passengers=${ticket.count_of_passengers}&manual_payment=${ticket.manual_payment}&time_interval=${ticket.time_interval}&datetime=${ticket.datetime}`
    : `${api_version}/service-zone/operator/tickets/buy?first_name=${ticket.first_name}&last_name=${ticket.last_name}&email=${ticket.email}&telephone_number=${ticket.telephone_number}&type_auto=${ticket.type_auto}&country_registration_id=${ticket.country_registration_id}&auto_registration_plate=${ticket.auto_registration_plate}&car_brand_id=${ticket.car_brand_id}&car_model_id=${ticket.car_model_id}&count_of_passengers=${ticket.count_of_passengers}&manual_payment=${ticket.manual_payment}&time_interval=${ticket.time_interval}&datetime=${ticket.datetime}`;
  return Axios.post(url, ticket);
};

export const ticketPricing = () => {
  const url = `${api_version}/common/ticket/pricing`;
  return Axios.get(url);
};

export const allCarBrands = () => {
  const url = `${api_version}/common/cars/brands`;
  return Axios.get(url);
};

export const allCarModels = (id) => {
  const url = `${api_version}/common/cars/models?car-brand-id=${id}`;
  return Axios.get(url);
};

export const allCountries = () => {
  const url = `${api_version}/common/countries`;
  return Axios.get(url);
};

export const timeSlots = (data) => {
  const url = `${api_version}/service-zone/operator/tickets/buy/time-slots?date=${data.date}&type_auto=${data.typeAuto}`;
  return Axios.get(url);
};

export const holdSlot = (data) => {
  const url = `${api_version}/service-zone/operator/tickets/buy/execute-hold-selected-slot?datetime=${data.datetime}&type_auto=${data.typeAuto}`;
  return Axios.post(url, data);
};

export const queueList = (page) => {
  const url = `${api_version}/service-zone/queue/table?page=${page}`;
  return Axios.get(url);
};

export const carTicketByUuid = (uuid) => {
  const url = `${api_version}/common/ticket?uuid=${uuid}`;
  return Axios.get(url);
};

export const manualCarDispatch = (autosCount) => {
  const url = `${api_version}/service-zone/queue/manual-car-dispatch?number-of-cars=${autosCount}`;
  return Axios.post(url);
};

export const replaceCarInQueue = (uuid) => {
  const url = `${api_version}/service-zone/queue/move-car-as-first-in-queue-of-waiting?uuid=${uuid}`;
  return Axios.post(url);
};

export const releaseCarFromQueue = (uuid) => {
  const url = `${api_version}/service-zone/queue/confirm-car-as-being-in-buffer-zone?uuid=${uuid}`;
  return Axios.post(url);
};

export const barriers = () => {
  const url = `${api_version}/service-zone/barrier`;
  return Axios.get(url);
};

export const raiseBarrier = (uuid) => {
  const url = `${api_version}/service-zone/barrier/raise?uuid=${uuid}`;
  return Axios.post(url);
};

export const scoreboards = () => {
  const url = `${api_version}/service-zone/scoreboard`;
  return Axios.get(url);
};

export const updateScoreboard = (scoreboard) => {
  const url = `${api_version}/service-zone/scoreboard?uuid=${scoreboard.uuid}&is_active=${scoreboard.is_active}`;
  return Axios.put(url);
};
