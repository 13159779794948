import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { getFromLS, isLSHasItem } from '@/library/helpers';
import { checkUserToken } from '@/api/config';

import VueI18n from '@/i18n';

Vue.use(VueRouter);
Vue.use(VueI18n);

const routes = [
  {
    path: '/',
    component: () => import('@/components/Main/Main.vue'),
    meta: {
      title: `${VueI18n.t('titles.main')}`,
    },
    children: [
      {
        path: '/sign-in',
        name: 'auth',
        component: () => import('@/components/Auth/Auth.vue'),
        meta: {
          title: `${VueI18n.t('titles.auth')}`,
        },
        beforeEnter: (to, from, next) => {
          if (to.name === 'auth' && checkUserToken()) {
            next({ name: 'dashboard' });
          } else {
            next();
          }
        },
      },
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/components/Dashboard/Dashboard.vue'),
        meta: {
          title: `${VueI18n.t('titles.dashboard')}`,
        },
      },
      {
        path: '/new-ticket',
        component: () => import('@/components/NewTicket/NewTicket.vue'),
        meta: {
          breadcrumb: {
            name: `${VueI18n.t('titles.new_ticket')}`,
          },
        },
        children: [
          {
            path: '',
            name: 'newTicketInfo',
            component: () => import('@/components/NewTicket/NewTicketInfo.vue'),
            meta: {
              breadcrumb: {
                name: `${VueI18n.t('titles.new_ticket')}`,
              },
              title: `${VueI18n.t('titles.new_ticket')}`,
            },
          },
          {
            path: 'date',
            name: 'newTicketDate',
            component: () => import('@/components/NewTicket/NewTicketDate.vue'),
            meta: {
              breadcrumb: {
                name: `${VueI18n.t('titles.new_ticket_date')}`,
              },
              title: `${VueI18n.t('titles.new_ticket')}`,
            },
          },
          {
            path: 'payment-choice',
            name: 'newTicketPaymentChoice',
            component: () =>
              import('@/components/NewTicket/NewTicketPaymentChoice.vue'),
            meta: {
              breadcrumb: {
                name: `${VueI18n.t('titles.new_ticket_payment_choice')}`,
              },
              title: `${VueI18n.t('titles.new_ticket')}`,
            },
          },
          {
            path: 'paid',
            name: 'newTicketPaid',
            component: () => import('@/components/NewTicket/NewTicketPaid.vue'),
            props: true,
            meta: {
              breadcrumb: {
                name: `${VueI18n.t('titles.new_ticket_paid')}`,
              },
              title: `${VueI18n.t('titles.new_ticket')}`,
            },
            beforeEnter: (to, from, next) => {
              if (to.name === 'newTicketPaid' && isLSHasItem('new-ticket')) {
                const newTicket = getFromLS('new-ticket');
                const autoPlateNumber = newTicket.auto_registration_plate;
                const typeAuto = VueI18n.t(
                  `new_ticket_info.${newTicket.type_auto}`
                ).toUpperCase();

                const breadcrumbName = `${autoPlateNumber} ${typeAuto}`;

                to.meta.breadcrumb.name = breadcrumbName;
                next();
              }
            },
          },
        ],
      },
      {
        path: '/queue',
        name: 'queue',
        component: () => import('@/components/Queue/Queue.vue'),
        meta: {
          breadcrumb: {
            name: `${VueI18n.t('titles.queue')}`,
          },
          title: `${VueI18n.t('titles.queue')}`,
        },
      },
      {
        path: '/barriers',
        name: 'barriers',
        component: () => import('@/components/Barriers/Barriers.vue'),
        meta: {
          breadcrumb: {
            name: `${VueI18n.t('titles.barriers')}`,
          },
          title: `${VueI18n.t('titles.barriers')}`,
        },
      },
      {
        path: '/scoreboards',
        name: 'scoreboards',
        component: () => import('@/components/Scoreboards/Scoreboards.vue'),
        meta: {
          breadcrumb: {
            name: `${VueI18n.t('titles.scoreboards')}`,
          },
          title: `${VueI18n.t('titles.scoreboards')}`,
        },
      },
      {
        path: '/:notFound(.*)*',
        name: 'notFound',
        component: () => import('@/components/NotFound/NotFound.vue'),
        meta: {
          title: `${VueI18n.t('titles.not_found')}`,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'auth' && !checkUserToken()) {
    store.commit('LOGOUT_USER');
    next({ name: 'auth' });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title =
      to.meta && to.meta.title
        ? `${to.meta.title} | ${process.env.VUE_APP_TITLE}`
        : process.env.VUE_APP_TITLE;
  });
});

export default router;
