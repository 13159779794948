import jwt_decode from 'jwt-decode';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import VueI18n from '@/i18n';

export const checkUserToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  if (token) {
    const decoded = jwt_decode(token);
    return Date.now() >= decoded.exp * 1000 ? false : token;
  }

  return false;
};

const authInterceptor = (config) => {
  const token = checkUserToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    store.commit('LOGOUT_USER');
  }

  return config;
};

const config = {
  baseURL: process.env.VUE_APP_API_HOST,
  timeout: 60 * 1000, // Timeout
};

const Axios = axios.create(config);

const errorComposer = (error) => {
  return () => {
    const statusCode = error.response ? error.response.status : null;

    if (
      statusCode === 500 &&
      error.response.data.message === 'Unauthenticated.'
    ) {
      store.commit('LOGOUT_USER');
      router.push({ name: 'auth' });
      return;
    }
    if (statusCode) {
      store.commit('setNotification', {
        type: 'danger',
        title: `${VueI18n.t('notification.error')} ${statusCode}`,
        text: `${VueI18n.t('notification.error_text')}`,
      });
      return;
    }
    store.commit('setNotification', {
      type: 'danger',
      title: `${VueI18n.t('notification.unknown_error')}`,
      text: `${VueI18n.t('notification.error_text')}`,
    });
  };
};

Axios.interceptors.request.use(authInterceptor);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    error.handleGlobally = errorComposer(error);
    return Promise.reject(error);
  }
);

export { Axios };
